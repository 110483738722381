<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <label for="category">Category</label>

        <b-form-select @change="getRandomPoolItem" v-model="category">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option v-for="(cat, idx) in rewardPoolCategories" :key="idx" :value="cat.id">{{
            cat.name.substring(0, 1).toUpperCase() + cat.name.substring(1)
          }}</b-form-select-option>
        </b-form-select>
      </div>
    </div>
    <div v-if="rewardPoolItem" class="result mt-4 d-flex w-100 justify-content-between align-items-center">
      <div class="item">
        <label for="reward_type">Reward Type</label>
        <p>{{ rewardPoolItem.reward_type }}</p>
      </div>
      <div class="item">
        <label for="reward_type">Amount</label>
        <p>{{ rewardPoolItem.amount }}</p>
      </div>
      <div class="item">
        <label for="reward_type">Multiplier</label>
        <p>{{ rewardPoolItem.multiplier }}</p>
      </div>
      <div class="item">
        <label for="reward_type">Duration</label>
        <p>{{ rewardPoolItem.duration }}</p>
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-center">
      <button :disabled="!rewardPoolItem" @click="onSubmit" class="btn btn-primary">Save</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
  created() {
    this.getRewardPoolCategories();
  },
  watch: {
    category(){
      if(!this.category){
        this.setRewardPoolItem(null);
      }
    }
  },
  props: {
    typeOfAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      category: null,
    };
  },
  methods: {
    ...mapMutations({
      populateCalendarCell: 'populateCalendarCell',
      setRewardPoolItem: 'setRewardPoolItem',
      populateEventCell: 'populateEventCell',
    }),
    ...mapActions({
      getRewardPoolCategories: 'getRewardPoolCategories',
      getRandomRewardPoolItemByCat: 'getRandomRewardPoolItemByCat',
    }),
    getRandomPoolItem() {
      this.getRandomRewardPoolItemByCat({ categoryId: this.category });
    },
    onSubmit() {
      if (this.typeOfAction === 'events') {
        this.populateEventCell({
          cell: this.selectedDate,
          data: this.rewardPoolItem,
          vm: this,
        });
      } else {
        this.populateCalendarCell({
          cell: this.typeOfAction === 'events' ? this.selectedDate : this.selectedDay,
          data: this.rewardPoolItem,
          vm: this,
        });
      }

      this.setRewardPoolItem(null);
    },
  },
  computed: {
    ...mapGetters({
      rewardPoolCategories: 'rewardPoolCategories',
      rewardPoolItem: 'rewardPoolItem',
      selectedDay: 'selectedDay',
      selectedDate: 'selectedDate',
    }),
  },
};
</script>

<style lang="scss" scoped>
.result {
  border: 1px solid #add8e6;
  border-radius: 8px;
  padding: 10px;

  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      font-weight: 700;
    }

    p {
      font-weight: 500;
      margin: 0;
    }
  }
}
</style>
