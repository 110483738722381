<template>
  <b-modal hide-footer size="lg" :id="id"
    ><b-tabs fill content-class="mt-3">
      <b-tab title="Add Fixed Reward" active>
        <AddFixedReward :typeOfAction="type" />
      </b-tab>
      <b-tab title="Add From Reward Pool"><AddFromRewardPool :typeOfAction="type"/></b-tab> </b-tabs
  ></b-modal>
</template>

<script>
import AddFixedReward from '../components/AddFixedReward';
import AddFromRewardPool from '../components/AddFromRewardPool';

export default {
  components: {
    AddFixedReward,
    AddFromRewardPool,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.modal .modal-content .modal-header .close:before {
  content: "" !important;
}
</style>