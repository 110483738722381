<template>
  <main>
    <h3 class="mb-5">Add Daily Rewards</h3>
    <div class="row">
      <div class="col-md-6">
        <label for="noOfDays">No Of Days</label>
        <b-form-select v-model="noOfDays">
          <b-form-select-option :value="null">Please select an option</b-form-select-option>
          <b-form-select-option v-for="(no, idx) in days" :key="idx" :value="no">{{ no }}</b-form-select-option>
        </b-form-select>
      </div>
      <div class="col-md-6">
        <label for="name">Name</label>
        <b-form-input @focusout="blurredOut = true" :class="{ 'not-valid': !isValid  }" v-model="name" placeholder="Name"></b-form-input>
        <span class="error-text" v-if="!isValid">Name of calendar should only be english letters and numbers</span>
      </div>
    </div>
    <div class="row mt-5">
      <div @click="openModal(idx)" v-for="(no, idx) in noOfDays" :key="idx" class="col-md-2 custom-card">
        <div class="day-no d-flex justify-content-start">{{ no }}</div>
        <template v-if="calendarRewards[idx]">
          <div
            v-if="calendarRewards[idx].rewardPoolCategory"
            class="d-flex flex-column align-items-center justify-content center"
          >
            <p class="data">{{ calendarRewards[idx].reward_type }}</p>
            <p
              class="data"
              v-if="calendarRewards[idx].reward_type === 'CASH' || calendarRewards[idx].reward_type === 'SUPER_CASH'"
            >
              {{ calendarRewards[idx].amount }}
            </p>
            <p class="data" v-if="calendarRewards[idx].reward_type === 'BOOST'">{{ calendarRewards[idx].duration }}</p>
            <p class="data" v-if="calendarRewards[idx].reward_type === 'BOOST'">
              {{ calendarRewards[idx].multiplier }}
            </p>
          </div>
          <div v-else class="d-flex flex-column align-items-center justify-content center">
            <p class="data">{{ calendarType(calendarRewards[idx].reward_type).toUpperCase() }}</p>
            <p class="data" v-if="calendarRewards[idx].reward_type === '0' || calendarRewards[idx].reward_type === '1'">
              {{ calendarRewards[idx].amount }}
            </p>
            <p class="data" v-if="calendarRewards[idx].reward_type === '2'">{{ calendarRewards[idx].duration }}</p>
            <p class="data" v-if="calendarRewards[idx].reward_type === '2'">{{ calendarRewards[idx].multiplier }}</p>
          </div>
        </template>
      </div>
    </div>
    <div v-if="noOfDays" class="d-flex justify-content-center mt-4">
      <button @click="onSubmit" :disabled="isDisabled" class="btn btn-primary">Submit</button>
    </div>
    <RewardModal type="calendar" id="add-daily-reward-modal" />
  </main>
</template>

<script>
import RewardModal from '../../modals/RewardModal';
import { mapMutations, mapGetters, mapActions } from 'vuex';

export default {
  components: {
    RewardModal,
  },
  watch: {
    noOfDays(){
      if(this.noOfDays < this.calendarRewards.length){
        const array = [...this.calendarRewards];
        array.splice(this.noOfDays);
        this.setCalendarRewards(array);
      }
    }
  },
  data() {
    return {
      noOfDays: null,
      name: null,
      loading: false,
      blurredOut: false,
      errors: {
        name: null,
      },
      days: Array(30)
        .join()
        .split(',')
        .map(
          function(a) {
            return this.i++;
          },
          { i: 1 },
        ),
    };
  },
  methods: {
    ...mapMutations({
      setSelectedDay: 'setSelectedDay',
      setCalendarRewards: 'setCalendarRewards',
    }),
    ...mapActions({
      saveCalendar: 'saveCalendar',
    }),
    openModal(no) {
      this.setSelectedDay(no);
      this.$bvModal.show('add-daily-reward-modal');
    },
    calendarType(type) {
      switch (type) {
        case '0':
          return 'Cash';
        case '1':
          return 'Super Cash';
        case '2':
          return 'Boost';
        default:
          return '';
      }
    },
    onSubmit() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      // if(/^\d+$/.test(this.name) || !this.name.match("^[A-Za-z0-9]+$")) {
      //   this.loading = false;
      //   this.errors.name = 'Name of calendar should only be english letters and numbers';
      //   return ;
      // }

      const data = {
        calendar: {
          name: this.name,
          calendarRewards: this.calendarRewards,
        },
      };

      this.saveCalendar({
        data: data,
        vm: this,
      });
    },
    resetFields() {
      this.noOfDays = null;
      this.name = null;
      this.setCalendarRewards(0);
      this.errors.name = null;
      this.blurredOut = false;
    },
  },
  computed: {
    ...mapGetters({
      calendarRewards: 'calendarRewards',
    }),
    isValid() {
      if (/^\d+$/.test(this.name) || (this.name && !this.name.match('^[A-Za-z0-9]+$')) || (this.blurredOut && !this.name)) {
        return false;
      } else {
        return true;
      }
    },
    isDisabled() {
      if (
        this.calendarRewards.length === 0 ||
        this.calendarRewards.length !== this.noOfDays ||
        !this.name ||
        this.name === '' ||
        !this.isValid
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
main {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  height: fit-content;
  h3 {
    font-size: 16px;
    font-weight: 700;
    color: #3c4eba;
  }

  label {
    color: #3c4eba;
    font-weight: 500;
  }

  .not-valid {
    border: 1px solid red;
    color: red;
  }

  .error-text {
    color: red;
    font-weight: 500;
    font-size: 11px;
  }

  .custom-card {
    background: #fff;
    cursor: pointer;
    padding: 0;
    padding-top: 5px;

    .day-no {
      background: #add8e6;
      padding-left: 5px;
      border-radius: 5px;
      margin-left: 5px;
      margin-right: 5px;
      margin-bottom: 8px;
    }

    &:nth-of-type(6n + 1) {
      border: 1px solid #c5c7cd;
      border-bottom: none;
    }

    &:nth-of-type(25) {
      border-bottom: 1px solid #c5c7cd;
    }

    &:not(:nth-of-type(6n + 1)) {
      border-top: 1px solid #c5c7cd;
      border-right: 1px solid #c5c7cd;
    }

    &:nth-last-of-type(-n + 6) {
      border-bottom: 1px solid #c5c7cd;
    }

    &:hover {
      background: #c5c7cd;

      p.data {
        color: white;
      }

      .day-no {
        background-color: white;
        color: #23395d;
      }
    }

    p.data {
      font-weight: 600;
      color: #23395d;
      margin: 0;
    }
  }
}
</style>
